import { create } from 'zustand';
import axios from 'axios';
import Cookies from 'js-cookie';
import { baseUrl } from '../utils/baseUrlApi';

const useAuthStore = create((set, get) => {
    const token = Cookies.get('authToken') || null;
    const user = Cookies.get('user') ? JSON.parse(Cookies.get('user')) : null;

    // Отложенный вызов getUser, чтобы избежать ошибки
    setTimeout(() => {
        if (token && !user) {
            get().getUser(token);
        }
    }, 0);

    return {
        user,
        token,
        isLoggedIn: !!token,
        isLoading: false,
        error: null,
        status: 'idle', // 'idle', 'loading', 'success', 'error'
        isError: false,
        isSuccess: false,

        getToken: async (username, password) => {
            set({ isLoading: true, error: null });
            try {
                const response = await axios.post(`https://support.pridex.ru/auth`, {
                    "username": username,
                    "password": password,
                }, {
                    headers: { "Content-Type": "application/json" }
                });
                const token = response.data.Token;
                Cookies.set('authToken', token, { expires: 0.5 });
                set({ token, isLoggedIn: true, isLoading: false, isError: false });
                await get().getUser(token);
            } catch (error) {
                set({ error: error.message, isLoading: false, isError: true, isSuccess: false });
                get().logout();
            }
        },

        getTokenByTgId: async (telegramId) => {
            set({ isLoading: true, error: null, status: 'loading' });
            try {
                const response = await axios.post(`https://support.pridex.ru/tg/verification_user`, {
                    "tg_id": telegramId,
                }, {
                    headers: {
                        "Content-Type": "application/json",
                        "Api_key_secret": process.env.REACT_APP_API_KEY_SECRET
                    }
                });
        
                const token = response.data.encoded_login;
                Cookies.set('authToken', token, { expires: 0.5 });
                await useAuthStore.getState().getUser(token);
        
                set({ token, isLoggedIn: true, isLoading: false, isError: false, status: 'success' });
            } catch (error) {
                console.error("Ошибка при получении токена:", error);
                set({
                    error: {
                        message: error.message,
                        responseData: error.response?.data,
                        status: error.response?.status,
                        headers: error.response?.headers,
                        config: error.config
                    },
                    isLoading: false,
                    status: 'error'
                });
                useAuthStore.getState().logout();
            }
        },
        

        getUser: async (token) => {
            set({ isLoading: true, error: null, status: 'loading' });
            try {
                const response = await axios.get(`${baseUrl}/mobiledatag/authenticateG`, {
                    headers: { "Authorization": `Basic ${token}` }
                });
                const userData = {
                    fullName: response.data.Full_name,
                    phoneNumber: response.data.User_number,
                    isKeyUser: response.data.Key_user,
                    isModerator: response.data.User_consumer,
                    iniciatorUuid: response.data.UIniciator,
                };
                set({ user: userData, isLoading: false, isSuccess: true, isLoggedIn: true });
                Cookies.set('user', JSON.stringify(userData), { expires: 0.5 });
            } catch (error) {
                const status = error.response?.status;
                const isForbidden = status === 401 || status === 403;
                if (isForbidden) {
                    Cookies.remove('authToken');
                }
                set({
                    error: error.response?.data || error.message,
                    isLoading: false,
                    status: 'error',
                    isLoggedIn: !isForbidden,
                });
                get().logout();
            }
        },

        logout: () => {
            Cookies.remove('authToken');
            Cookies.remove('user');
            set({ token: null, user: null, isLoggedIn: false, status: 'idle' });
        }
    };
});


export default useAuthStore;
