import axios from "axios";
import { create } from "zustand";
import { baseUrl } from '../utils/baseUrlApi';
import Cookies from 'js-cookie';
import { sortObjectByKey } from "../utils/SortFunctions";
import useAuthStore from "./useAuthStore";

const getAuthHeaders = () => ({
    "Content-Type": "application/json",
    "Authorization": `Basic ${Cookies.get('authToken')}`
});

const ensureUser = async () => {
    let user = Cookies.get("user") ? JSON.parse(Cookies.get("user")) : null;
    if (!user) {
        const { token, getUser } = useAuthStore.getState();
        if (token) {
            await getUser(token);
            user = Cookies.get("user") ? JSON.parse(Cookies.get("user")) : null;
        }
    }
    return user;
};

const useUserIncidentStore = create((set) => ({
    incidentList: null,
    isIncLoading: false,
    isListLoading: false,
    error: null,
    warningError: false,
    selectedIncidentUuid: null,
    selectedIncident: null,
    workgroupList: null,
    servicesList: null,
    serviceComponentList: null,
    responsibleList: null,
    showInWork: true,
    setWarningError: (warningError) => set({ warningError }),
    setSelectedIncident: (selectedIncident) => set({ selectedIncident }),
    setSelectedIncidentUuid: (selectedIncidentUuid) => set({ selectedIncidentUuid }),
    setShowInWork: (showInWork) => set({ showInWork }),
    getIncidentList: async (showInWork) => {
        try {
            set({ isListLoading: true });
            
            const user = await ensureUser();
            if (!user) {
                set({ error: "User not found", isListLoading: false, warningError: true });
                return;
            }
    
            const response = await axios.post(`${baseUrl}/externalapi/getIncidentsList/`,
                {
                    initiatorUuid: user.iniciatorUuid,
                    stateUuid: !showInWork ? '' : "dbf7190b-44a3-11ed-9b01-0050569f5691,dbf7190f-44a3-11ed-9b01-0050569f5691,dbf71911-44a3-11ed-9b01-0050569f5691,dbf71913-44a3-11ed-9b01-0050569f5691,dbf71915-44a3-11ed-9b01-0050569f5691,4acdf1bd-4434-11ef-a686-005056899ff4,34407658-e213-11ee-90b3-0050569f9f23,dbf7190c-44a3-11ed-9b01-0050569f5691,dbf71910-44a3-11ed-9b01-0050569f5691,dbf71912-44a3-11ed-9b01-0050569f5691"
                },
                {
                    headers: getAuthHeaders()
                }
            );
    
            set({
                isListLoading: false,
                incidentList: response.data.Incidents.sort((a, b) => parseInt(b.number, 10) - parseInt(a.number, 10)),
                error: null,
                warningError: false
            });
    
        } catch (error) {
            console.error('Error in Axios request', error);
            set({ error: error.message, isListLoading: false, warningError: true });
        }
    },
    
    fetchIncident: async (uuid) => {
        set({ isLoading: true });
        try {
            set({ isIncLoading: true });
    
            const user = await ensureUser();
            if (!user) {
                set({ error: "User not found", isIncLoading: false });
                return;
            }
    
            const response = await axios.post(`${baseUrl}/externalapi/extGetDetailIncidentInfo`,
                { uuid },
                { headers: getAuthHeaders() }
            );
    
            set({
                selectedIncident: response.data,
                isIncLoading: false
            });
        } catch (error) {
            console.error('Error fetching incident', error);
            set({ error: error.message, isIncLoading: false, selectedIncidentUuid: null });
        }
    },
    

    fetchIncidentSmooth: async (uuid) => {
        try {
            const response = await axios.post(`${baseUrl}/externalapi/extGetDetailIncidentInfo`,
                {
                    uuid: uuid
                },
                {
                    headers: getAuthHeaders()
                });
            set({
                selectedIncident: response.data,
            });
        } catch (error) {
            console.error('Error fetching incident', error);
            set({ error: error.message, selectedIncidentUuid: null });
        }
    }
}));

export default useUserIncidentStore;