import { useEffect, useRef, useState } from "react";
import useOptionsStore from "../../../stores/useOptionsStore";
import useThemeStore from "../../../stores/useThemeStore";
import './IncidentPopup.css';
import { Services } from "./ServiceConstants";
import { ServiceComponentItem, ServiceItem } from "./ServiceItem";
import { useNavigate, useSearchParams } from "react-router-dom";
import usePopupStore from "../../../stores/usePopupStore";
import { ReactComponent as Back } from "../../../utils/images/back.svg";
import { ReactComponent as Close } from "../../../utils/images/close.svg";
import { AnimatePresence, motion } from "framer-motion";
import { ReactComponent as Loading } from '../../../utils/images/bouncing-loading.svg';
import AnimateChangeInHeight from "../../../utils/AnimateHeightInChange";
import DefaultForm from "./CustomForms/DefaultForm";
import Success from "./CustomForms/Success";
import Error from "./CustomForms/Error";
import { ReactComponent as ErrorSVG } from '../../../utils/images/incident-error.svg';
import useUserIncidentStore from "../../../stores/useUserIncidentStore";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import LocationForm from "./CustomForms/LocationForm";
import CPOForm from "./CustomForms/CPOForm";
import BankTrustForm from "./CustomForms/BankTrustForm";
import PCVPNSetup from "./CustomForms/PCVPNSetup";
import MobileMailForm from "./CustomForms/MobileMailForm";
import FolderAccessForm from "./CustomForms/FolderAccessForm";
import NumberRedirectForm from "./CustomForms/NumberRedirectForm";
import Rework1CForm from "./CustomForms/Rework1CForm";
import useAuthStore from "../../../stores/useAuthStore";
function IncidentPopupDesktop() {
    const colorScheme = useThemeStore();
    const { currentColorScheme } = useThemeStore();
    const colorSchemeClassName = `_${colorScheme.getColorScheme() === 'dark' ? 'darkTheme' : 'lightTheme'}`;
    const [searchParams, setSearchParams] = useSearchParams();
    const { isMobile } = useOptionsStore();
    const { user } = useAuthStore();
    const isKeyUser = user?.isKeyUser;
    const isPopupOpened = searchParams.get('popup') === 'true';
    const serviceUuid = searchParams.get('serviceUuid');
    const serviceComponentUuid = searchParams.get('serviceComponentUuid');
    const {
        fetchServiceComponent,
        serviceComponents,
        setServiceComponents,
        isComponentsLoading,
        isSuccess,
        isError,
        setIsSuccess,
        setIsError,
        setSelectedService
    } = usePopupStore();
    const { getIncidentList } = useUserIncidentStore();
    const [currentStep, setCurrentStep] = useState(0);
    const iconColor = colorScheme.getColorScheme() === 'dark' ? '#fff9' : '#0082C8';
    const popupRef = useRef();
    const calendarRef = useRef();
    const selectedService = Services?.find((item) => item.ServiceUuid === serviceUuid);
    const selectedServiceComponent = serviceComponents?.find((item) => item.ServiceComponentUuid === serviceComponentUuid);

    const blockTransition = isMobile
        ? {
            initial: { opacity: 0, x: 20 },
            animate: { opacity: 1, x: 0 },
            exit: { opacity: 0, x: -20 },
            transition: { duration: 0.3, type: 'spring', stiffness: 300, damping: 30 },
        }
        : {
            initial: { opacity: 0 },
            animate: { opacity: 1 },
            exit: { opacity: 0 },
            transition: { duration: 0.3, type: 'Inertia', staggerChildren: 0.2 },
        };




    const closePopup = () => {
        setIsSuccess(false);
        setIsError(false);
        setSearchParams({});
        setCurrentStep(0);
    };

    const goToStep = (step, params = {}) => {
        setCurrentStep(step);
        const newParams = new URLSearchParams(searchParams);
        newParams.set('popup', 'true');

        if (step === 0) {
            newParams.delete('serviceUuid');
            newParams.delete('serviceComponentUuid');
        } else if (step === 1 && params.serviceUuid) {
            newParams.set('serviceUuid', params.serviceUuid);
            newParams.delete('serviceComponentUuid');
        } else if (step === 2 && params.serviceComponentUuid) {
            newParams.set('serviceComponentUuid', params.serviceComponentUuid);
        }

        setSearchParams(newParams);
    };

    const renderSteps = () => {
        switch (currentStep) {
            case 0:
                return (
                    <motion.div
                        initial={blockTransition.initial}
                        animate={blockTransition.animate}
                        exit={blockTransition.exit}
                        transition={blockTransition.transition}
                        className="popup__grid">
                        {isMobile ? (
                            <OverlayScrollbarsComponent style={{ width: '100%' }}>
                                {Services.map((item, index) => (
                                    <ServiceItem
                                        key={`${item.ServiceUuid}_${index}`}
                                        onClick={() => {
                                            goToStep(1, { serviceUuid: item.ServiceUuid });
                                            setSelectedService(item);
                                        }}
                                        title={item.Service}
                                        image={item.image}
                                        uuid={item.ServiceUuid}
                                        disabled={item.disabled}
                                    />
                                ))}
                            </OverlayScrollbarsComponent>
                        ) : (
                            Services.map((item, index) => (
                                <ServiceItem
                                    key={`${item.ServiceUuid}_${index}`}
                                    onClick={() => {
                                        goToStep(1, { serviceUuid: item.ServiceUuid });
                                        setSelectedService(item);
                                    }}
                                    title={item.Service}
                                    image={item.image}
                                    uuid={item.ServiceUuid}
                                    disabled={item.disabled}
                                />
                            ))
                        )}
                    </motion.div>
                );
            case 1:
                return (

                    isMobile ? (
                        <motion.div
                            initial={blockTransition.initial}
                            animate={blockTransition.animate}
                            exit={blockTransition.exit}
                            transition={blockTransition.transition}
                            className="popup__grid"
                        >
                            <OverlayScrollbarsComponent>
                                {serviceComponents
                                    ?.filter(item => !(item.ServiceComponent === 'Доработка в 1С' && !isKeyUser))
                                    .map((item, index) => (
                                        <ServiceComponentItem
                                            key={`${item.ServiceComponentUuid}_${index}`}
                                            onClick={() => goToStep(2, { serviceComponentUuid: item.ServiceComponentUuid })}
                                            title={item.ServiceComponent}
                                            description={item.ServiceDescription}
                                            image={item.image}
                                            uuid={item.ServiceComponentUuid}
                                            delay={index * 0.2}
                                        />
                                    ))}
                            </OverlayScrollbarsComponent>
                        </motion.div>
                    ) : (
                        isError ? (
                            <div className="user-incident-list__empty-block">
                                <ErrorSVG />
                                <p style={{ fontWeight: 'bold', fontSize: '16px', marginTop: '5px' }}>Что-то пошло не так...</p>
                                <p style={{ maxWidth: '240px', fontSize: '14px' }}>Произошла ошибка при загрузке услуг.</p>
                            </div>
                        ) : (
                            <motion.div
                                initial={blockTransition.initial}
                                animate={blockTransition.animate}
                                exit={blockTransition.exit}
                                transition={blockTransition.transition}
                                className="popup__grid"
                            >
                                {serviceComponents
                                    ?.filter(item => !(item.ServiceComponent === 'Доработка в 1С' && !isKeyUser))
                                    .map((item, index) => (
                                        <ServiceComponentItem
                                            key={`${item.ServiceComponentUuid}_${index}`}
                                            onClick={() => goToStep(2, { serviceComponentUuid: item.ServiceComponentUuid })}
                                            title={item.ServiceComponent}
                                            description={item.ServiceDescription}
                                            image={item.image}
                                            uuid={item.ServiceComponentUuid}
                                        />
                                    ))}
                            </motion.div>
                        )
                    )
                );
            case 2:
                if (!selectedServiceComponent) return null;

                const formComponentsMap = {
                    'Подключение к сети (Wi-Fi / Провод)': <LocationForm setCurrentStep={setCurrentStep} />,
                    'Доработка в 1С': !isMobile ? <Rework1CForm setCurrentStep={setCurrentStep} /> : <DefaultForm setCurrentStep={setCurrentStep} />,
                    'Предоставление доступа к папкам': !isMobile ? <FolderAccessForm setCurrentStep={setCurrentStep} /> : <DefaultForm setCurrentStep={setCurrentStep} />,
                    'Установка ПО и ОС': !isMobile ? <CPOForm setCurrentStep={setCurrentStep} /> : <DefaultForm setCurrentStep={setCurrentStep} />,
                    // 'Выдача нового оборудования': DeviceTypeInStep,  нужен select multi корректный
                    // 'Предоставление новой оргтехники / расходных материалов': OrgTypeInStep,  нужен select multi корректный
                    'Ошибка датчика БИО': <LocationForm setCurrentStep={setCurrentStep} />,
                    'FaceID': <LocationForm setCurrentStep={setCurrentStep} />,
                    'Бронирование рабочего места / локера': <LocationForm setCurrentStep={setCurrentStep} />,
                    'ВКС / Переговорные / Мероприятия': <LocationForm setCurrentStep={setCurrentStep} />,
                    'Другое': <LocationForm setCurrentStep={setCurrentStep} />,
                    'Диагностика работы сети | Проблема с доступом в интернет': <LocationForm setCurrentStep={setCurrentStep} />,
                    'Другие вопросы с локальной сетью | Wi-Fi | Интернетом': <LocationForm setCurrentStep={setCurrentStep} />,
                    'Подключение к сети (Wi-Fi / Провод)': <LocationForm setCurrentStep={setCurrentStep} />,
                    'Выдача / Замена сим-карты': <LocationForm setCurrentStep={setCurrentStep} />,
                    'Другие вопросы по оборудованию': <LocationForm setCurrentStep={setCurrentStep} />,
                    'Замена текущего оборудования | Доукомплектация': <LocationForm setCurrentStep={setCurrentStep} />,
                    'Некорректная работа оборудования': <LocationForm setCurrentStep={setCurrentStep} />,
                    'Другие вопросы связанные с оргтехникой': <LocationForm setCurrentStep={setCurrentStep} />,
                    'Настройка сетевого сканнирования': <LocationForm setCurrentStep={setCurrentStep} />,
                    'Проблемы с печатью / МФУ / Плоттером': <LocationForm setCurrentStep={setCurrentStep} />,
                    'Другие вопросы с Удаленным доступом / VPN / VDI': <LocationForm setCurrentStep={setCurrentStep} />,
                    'Ошибка подключения VDI': <LocationForm setCurrentStep={setCurrentStep} />,
                    'Ошибка подключения VPN': <LocationForm setCurrentStep={setCurrentStep} />,
                    // 'Подключение МФУ / Плоттера': OrgTypeInStep, нужен select multi корректный
                    'Выпуск ЭЦП / доверенности (МЧД)': !isMobile ? <BankTrustForm setCurrentStep={setCurrentStep} /> : <DefaultForm setCurrentStep={setCurrentStep} />,
                    'Настройка доступа в СБИС': !isMobile ? <BankTrustForm setCurrentStep={setCurrentStep} /> : <DefaultForm setCurrentStep={setCurrentStep} />,
                    'Настройка подключения VPN': !isMobile ? <PCVPNSetup setCurrentStep={setCurrentStep} /> : <DefaultForm setCurrentStep={setCurrentStep} />,
                    // 'Подключение роуминга': Roaming, нужен date picker
                    'Настройка переадресации': !isMobile ? <NumberRedirectForm setCurrentStep={setCurrentStep} calendarRef={calendarRef} /> : <DefaultForm setCurrentStep={setCurrentStep} />,
                    'Настройка почты на мобильном телефоне': <MobileMailForm setCurrentStep={setCurrentStep} />
                };

                return formComponentsMap[selectedServiceComponent.ServiceComponent] || <DefaultForm setCurrentStep={setCurrentStep} />;


            case 3:
                return (
                    <>
                        {isSuccess && <Success />}
                        {isError && <Error />}
                    </>
                );
            default:
                return null;
        }
    };

    const disableIOSTextFieldZoom = () => {
        if (!isIOS()) { return }
        const element = document.querySelector('meta[name=viewport]')
        if (element !== null) {
            let content = element.getAttribute('content')
            let scalePattern = /maximum\-scale=[0-9\.]+/g
            if (scalePattern.test(content)) {
                content = content.replace(scalePattern, 'maximum-scale=1.0')
            } else {
                content = [content, 'maximum-scale=1.0'].join(', ')
            }
            element.setAttribute('content', content)
        }
    }

    const isIOS = () => {
        return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
    }

    disableIOSTextFieldZoom()

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (popupRef.current && (!popupRef.current.contains(event.target))) {
                closePopup();
            }
        };

        if (isPopupOpened) {
            document.addEventListener('mousedown', handleOutsideClick);
        } else {
            document.removeEventListener('mousedown', handleOutsideClick);
            if (isSuccess) {
                setTimeout(() => getIncidentList(), 50);
            }
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [isPopupOpened]);

    useEffect(() => {
        setServiceComponents(null);
        if (serviceUuid) {
            fetchServiceComponent(serviceUuid);
        }
    }, [serviceUuid, fetchServiceComponent, setServiceComponents]);

    useEffect(() => {
        if (serviceComponentUuid) {
            setCurrentStep(2);
        } else if (serviceUuid) {
            setCurrentStep(1);
        } else {
            setCurrentStep(0);
        }
    }, [serviceUuid, serviceComponentUuid]);

    useEffect(() => {
        if (currentStep === 3) {
            if (isSuccess) {
                setTimeout(() => getIncidentList(), 50);
            }
        }
    }, [currentStep]);

    return (
        <>
            <AnimatePresence>
                {isPopupOpened && (
                    <motion.div
                        className="popup__layout"
                        key="incidents-popup"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }}>

                        <div className={`popup__body popup__body${colorSchemeClassName}`} ref={popupRef}>
                            <div className="popup__button-block">
                                {(currentStep > 0 & currentStep !== 3) ? (
                                    <button
                                        className="popup__back-button"
                                        onClick={() => {
                                            if (currentStep > 0) {
                                                goToStep(currentStep - 1);
                                            }
                                        }}>
                                        <Back color={iconColor} />
                                    </button>
                                ) : null}
                                {(currentStep === 2 & !isComponentsLoading) ? (
                                    <motion.p
                                        style={{ fontSize: '16px', margin: 'auto' }}
                                        initial={blockTransition.initial}
                                        animate={blockTransition.animate}
                                        exit={blockTransition.exit}
                                        transition={blockTransition.transition}
                                        className={`p${colorSchemeClassName}`}>
                                        Выбранная услуга:
                                    </motion.p>
                                ) : null}
                                {currentStep === 0 && (
                                    <motion.p
                                        style={{ fontWeight: 'bold', fontSize: '16px', margin: 'auto' }}
                                        initial={blockTransition.initial}
                                        animate={blockTransition.animate}
                                        exit={blockTransition.exit}
                                        transition={blockTransition.transition}
                                        className={`p${colorSchemeClassName}`}>
                                        С чем вам нужна помощь?
                                    </motion.p>
                                )}
                                {currentStep === 1 && (
                                    <motion.p
                                        style={{ fontSize: '16px', margin: 'auto', textAlign: 'center', fontWeight: 'bold' }}
                                        initial={blockTransition.initial}
                                        animate={blockTransition.animate}
                                        exit={blockTransition.exit}
                                        transition={blockTransition.transition}
                                        className={`p${colorSchemeClassName}`}>
                                        {!isError && selectedService?.Service}
                                    </motion.p>
                                )}
                                <button style={{ marginLeft: currentStep === 3 ? 'auto' : '0' }} className="popup__close-button" onClick={closePopup}>
                                    <Close color={iconColor} />
                                </button>
                            </div>
                            {isComponentsLoading ? (
                                <motion.div
                                    className="popup__loading"
                                    key="loading-indicator"
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    transition={{ duration: 0.3 }}>
                                    <Loading width={isMobile ? 60 : 75} height={isMobile ? 60 : 75} />
                                </motion.div>
                            ) : (
                                <AnimatePresence mode="wait">
                                    {renderSteps()}
                                </AnimatePresence>
                            )}
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    );
}

export default IncidentPopupDesktop;

