export function validateUsername(username) {
    const allowedCharacters = /^[a-zA-Z0-9.\-]*$/;
    let invalidChars = '';
    for (const char of username) {
        if (!allowedCharacters.test(char)) {
            invalidChars += char;
        }
    }
    if (invalidChars.length > 0) {
        return { isValid: false, invalidChars: invalidChars };
    } else {
        return { isValid: true };
    }
}